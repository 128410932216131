import React, { useState, useRef, useEffect } from "react";
import DownloadTableButton from "components/DownloadTableButton/DownloadTableButton";
import { Col, Row, Button, Table,Modal,notification } from "antd";
import { useJsApiLoader,InfoWindow, Circle,GoogleMap, Polygon, Marker,Polyline,useLoadScript } from '@react-google-maps/api';
import { useSelector, useDispatch } from "react-redux";
import apiHandler from "api";
import endpoint from "api/endpoint";
import moment from "moment";
import styled from "styled-components";
import Logo from "assets/logo.png";
import InputAntd from "components/InputAntd/InputAntd";
import { isEmpty } from "lodash";
const containerStyle = {
  width: '100%',
  height: '100%'
};

export const StyledModal = styled(Modal)`
  border-radius: 10px;
`;
const GpsHistory = () => {
  const mapRef = useRef(null);
  const { authToken } = useSelector((state) => state.login);
  const { participantProfileData } = useSelector(
    (state) => state.participantProfileData
  );
  const [center,setCenter] = useState({lat: 37.772, lng: -122.214})
  const [path,setPath]  = useState([
    {lat: 37.772, lng: -122.214},
    {lat: 21.291, lng: -157.821},
    {lat: -18.142, lng: 178.431},
    {lat: -27.467, lng: 153.027}
  ]);
  const [isZoomSet, zoomSet] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(4);
  useEffect(() => {
    // console.log('=======zpsZoomLevel=====init===', localStorage.getItem('zpsZoomLevel'));
    if (localStorage.getItem('zpsZoomLevel')&&!isZoomSet) {
      zoomSet(true);
    // console.log(isEmpty(localStorage.getItem('zpsZoomLevel')),'======22222222==========', localStorage.getItem('zpsZoomLevel'), isNaN(localStorage.getItem('zpsZoomLevel')));

      setZoomLevel(isNaN(localStorage.getItem('zpsZoomLevel'))||!localStorage.getItem('zpsZoomLevel')?3:parseInt(localStorage.getItem('zpsZoomLevel')+''));
      setCenter(
        {
          lat: localStorage.getItem('zpsCenterLat') ? parseFloat(localStorage.getItem('zpsCenterLat')) : 37.772, 
          lng: localStorage.getItem('zpsCenterLng') ? parseFloat(localStorage.getItem('zpsCenterLng')) : -122.214
        }
      );
      // console.log('=======zpsZoomLevel=====init=22==', localStorage.getItem('zpsZoomLevel'));
    } 
    // if (localStorage.getItem('zpsZoomLevel')&&!isZoomSet) {
    //   zoomSet(true);
    //   setZoomLevel(localStorage.getItem('zpsZoomLevel'));
    //   setCenter(
    //     {
    //       lat: localStorage.getItem('zpsCenterLat') ? localStorage.getItem('zpsCenterLat') : 37.772, 
    //       lng: localStorage.getItem('zpsCenterLng') ? localStorage.getItem('zpsCenterLng') : -122.214
    //     }
    //   );
    //   console.log('=======zpsZoomLevel=====init===', localStorage.getItem('zpsZoomLevel'));
    // } 
  }, [zoomLevel]);
  
  const [zoneData, setZoneData] = useState([]);
  const [rowData, setRowData] = useState(null);
  const { historyStartDate, historyEndDate } = useSelector((state) => state.common);
  const [eventGpsData, setEventGpsData] = useState();
  const dispatch = useDispatch();
  const googleKey = "AIzaSyB2XkjP3mcKLJWvigvvhOBG0QF4msTR6EQ";
  const [isPopUp, setIsPopUp] = useState(false);
  const [dynamicOptions, setDynamicOptions] = useState({
    strokeColor: "#32a858",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#32a858",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    radius: 50,
    visible: true,
    zIndex: 1,
  });
  const [circleRadius, setCircleRadius] = useState(50);
  function convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
  }
  const sortByDate = (a, b)=> {
    if (a.recordedAt < b.recordedAt) {
        return -1;
    }
    if (a.recordedAt > b.recordedAt) {
        return 1;
    }
    return 0;
}
  

const fetchParticipantZones = () => {
  apiHandler({
    url: endpoint.AVAILABLE_ZONES + "/" + `${participantProfileData?.id}` + "/zones",
    authToken,
  }).then((result) => {
    setZoneData(result.data);
  });
};
  useEffect(() => {
    fetchParticipantZones();
    // Geocode.fromLatLng("37.772", "-122.214").then(
    //   (response) => {
    //     const address = response.results[0].formatted_address;
    //     console.log("address here",address);
    //   },
      
    //   (error) => {
    //     console.error(error);
    //   }
    // );

    // console.log('historyStartDate in gps history', historyStartDate, historyStartDate.format("YYYY-MM-DDTHH:mm:Z"));
    if (historyStartDate) {
      let urlData = endpoint.ENROLL_PARTICIPANT + "/"+participantProfileData?.id+"/gps/";
      if (historyStartDate && historyEndDate) {
        urlData += (((typeof historyStartDate === 'string' || historyStartDate instanceof String )? historyStartDate:  historyStartDate.format("YYYY-MM-DDTHH:mm")+moment().format("Z").replaceAll(":","")) || moment().format("YYYY-MM-DDTHH:mm")+moment().format("Z").replaceAll(":","")) + "/" +((typeof historyEndDate === 'string' || historyEndDate instanceof String )? historyEndDate:  historyEndDate.format("YYYY-MM-DDTHH:mm")+moment().format("Z").replaceAll(":",""));
        
      } else if (historyStartDate) {
        urlData += (((typeof historyStartDate === 'string' || historyStartDate instanceof String )? historyStartDate:  historyStartDate.format("YYYY-MM-DDTHH:mm")+moment().format("Z").replaceAll(":","")) || moment().format("YYYY-MM-DDTHH:mm")+moment().format("Z").replaceAll(":",""))
        
      }
    apiHandler({
      url: urlData,
      // url: endpoint.ENROLL_PARTICIPANT + "/" + participantProfileData?.id +"/gps/" + (historyStartDate.format("YYYY-MM-DD") || moment().format("YYYY-MM-DD")),
      authToken,
    }).then((result) => {
      if (!result) return;
      if(result?.data.length > 0){
        let gpsList = [];
        let lngLat = [];
        //let all_data = result.data.sort(sortByDate);
        //console.log("all_data",all_data);
      //  let resultData =  all_data.sort(function(a, b) {
      //     var keyA = new Date(a.recordedAt),
      //       keyB = new Date(b.recordedAt);
      //     // Compare the 2 dates
      //     if (keyA < keyB) return -1;
      //     if (keyA > keyB) return 1;
      //     return 0;
      //   });
        result?.data.map((row,i) => {
          var tz = moment.tz.guess();
          row.recordedAt = convertTZ(row.recordedAt, tz);
          row.receivedAt = convertTZ(row.receivedAt, tz);
          
         
          if(row?.latitude!==0 && row?.longitude !==0){
            const objItem2 = {
              lat: row?.latitude,
              lng: row?.longitude
            }
            if(i===0){
              setMapData([objItem2]);
              setCenter(objItem2);
            }
           
          
          //   fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${row?.latitude},${row?.longitude}&key=${googleKey}`).then(res => res.json())
          // .then(add => {
            
            //if(add?.plus_code?.compound_code){
              // row["location"] = add.plus_code.compound_code;
              row["location"] =  row?.latitude +","+row?.longitude
              // console.log("add",add)
              const objItem = {
                lat:row?.latitude,
                lng: row?.longitude,
                index:  (i+1)
              }
              // const objItem = new window.google.maps.LatLng(row?.latitude, 
              //   row?.longitude);
              lngLat.push(objItem);
              const obj = {
                key: (i+1),
                eventdate: moment(result?.data[i].recordedAt).format("MM/DD/YYYY h:mm A z"),
                eventD: row.recordedAt ? moment(row.recordedAt) : null,
                location: row?.location,
                battery: row?.deviceBatteryLevel.toString() +"%",
                charger: 'Off',
                strap: row?.alarmStrap.toString(),
                speed: (row?.speed)+' mph',
                latitude:row?.latitude,
                longitude: row?.longitude
              }
              gpsList.push(obj);
            }
       //   });
            
      //    }
          
        });

        // setEventGpsData(result?.data.map((row,i) =>({
        //   key: (i+1),
        //   eventdate: row?.recordedAt,
        //   location: row?.location,
        //   battery: row?.deviceBatteryLevel.toString() +"%",
        //   charger: 'Off',
        //   strap: row?.alarmStrap.toString(),
        //   speed: (row?.speed)+' mph',
        // })));

        // console.log(result?.data.length)
        

        setTimeout(() => {
          //if(result?.data.length == gpsList.length){
            //console.log("gps List here",lngLat)
            setEventGpsData(gpsList);
         // }
         // console.log("lat long here",lngLat);
          setPath(lngLat);
        }, 500);
      } else {
        setEventGpsData([]);
        setPath([]);
      }
    });
  }
  }, [participantProfileData, historyStartDate, historyEndDate]);
  
  const updateLocation = (gpsList) => {
    //console.log(gpsList)
    setEventGpsData(gpsList.map((row,i) =>({
      key: (i+1),
      eventdate: moment(row?.recordedAt).format("MMM D, YYYY h:mm A z"),
      eventD: row.recordedAt ? moment(row.recordedAt) : null,
      // eventT: moment(row?.recordedAt).format("h:mm A z"),
      location: row?.location,
      battery: row?.deviceBatteryLevel.toString() +"%",
      charger: 'Off',
      strap: row?.alarmStrap.toString(),
      speed: (row?.speed)+' mph',
    })));
  }

  // Set google map API
  const [mapData, setMapData] = useState([{lat: 37.772, lng: -122.214}]);
  const [ libraries ] = useState(['places']);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyB2XkjP3mcKLJWvigvvhOBG0QF4msTR6EQ",
    libraries: libraries,
  })

  const [map, setMap] = React.useState(null)
  const [copyUrl, setCopyUrl] = useState("");
  const [copyModalVisible, setCopyModalVisible] = useState(false);
  const onLoad = React.useCallback(function callback(map) {
   // const bounds = new window.google.maps.LatLngBounds(center);
    //map.fitBounds(bounds);
    // console.log(isNaN(localStorage.getItem('zpsZoomLevel'))||!localStorage.getItem('zpsZoomLevel'),'======1111111111==========', localStorage.getItem('zpsZoomLevel'), isNaN(localStorage.getItem('zpsZoomLevel')));
    map.setZoom(isNaN(localStorage.getItem('zpsZoomLevel'))||!localStorage.getItem('zpsZoomLevel')?3:parseInt(localStorage.getItem('zpsZoomLevel')+''));

    setMap(map)
  }, [])
  const onBoundsChanged = React.useCallback(function callback(map) {
   // map.setZoom(2)

  //  setMap(map)
  }, [])

  const onBoundsChange = () => {

    
   // map.setZoom(2)

  //  setMap(map)
  };
  
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const handleCancel = () => {
    setCopyModalVisible(false);
  };

  const csvHeaders = [
    {
        label: "Term",
        key: "term",
      },
      {
        label: "Definition",
        key: "definition",
      }
  ];

  const data = [{
      term : 'Mark as Definition',
      definition : 'If you want to add this event to the Violation History page and report, select this checkbox.'
  }, {
      term : 'Pinned',
      definition : 'Selecting the pin icon will prioritize this event and place it on the Caseload page.'
  }, {
      term : 'Created At',
      definition : 'This is the date and time the event occurred.'
  }, {
      term : 'Received At',
      definition : 'This is the date and time the event data was received by the server.'
  }, {
      term : 'Compliant At',
      definition : 'This is the date and time the event ended and compliance is resumed. Examples of how compliance is resumed include: A low battery being charged; a subsequent negative alcohol reading; airplane mode being turned off; re-entering an inclusion zone; exiting an exclusion zone; turning on location permissions, etc.'
  }, {
      term : 'Notification At',
      definition : 'This is the date and time a notification regarding the event was sent to Alert Recipients.'
  }];
 
  const gpsColumn = [
    {
      title: 'Event Date',
      dataIndex: 'eventD',
      key: 'eventD',
      render: (eventD) => <>
        <div style={{minWidth: '90px'}}>{eventD ? eventD.format("MM/DD/YYYY"): ''}</div>
        <div>{eventD ? eventD.format("h:mm A z"): ''}</div>
      </>,
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'Battery',
      dataIndex: 'battery',
      key: 'battery',
    },
    {
      title: 'Charger',
      dataIndex: 'charger',
      key: 'charger',
    },
    {
      title: 'Strap',
      dataIndex: 'strap',
      key: 'strap',
    },
    {
      title: 'Speed',
      dataIndex: 'speed',
      key: 'speed',
    },
  ];
  const [position,setPosition] = useState({});

  const divStyle = {
    background: `white`,
    border: `1px solid #ccc`,
    padding: 15
  }
  
  const onLoadInfo = infoWindow => {
    console.log('infoWindow: ', infoWindow)
  }
  const gpsData = [
    {
      key: '1',
      eventdate: '06/02/2022 11:58:10 pm',
      location: '8655 North Bremetowe Pwy Chicago, IL 60606',
      battery: '100%',
      charger: 'Off',
      strap: 'On',
      speed: '0 mph',
    },
    {
      key: '1',
      eventdate: '06/02/2022 11:58:10 pm',
      location: '8655 North Bremetowe Pwy Chicago, IL 60606',
      battery: '100%',
      charger: 'Off',
      strap: 'On',
      speed: '0 mph',
    },
    {
      key: '1',
      eventdate: '06/02/2022 11:58:10 pm',
      location: '8655 North Bremetowe Pwy Chicago, IL 60606',
      battery: '100%',
      charger: 'Off',
      strap: 'On',
      speed: '0 mph',
    },
    {
      key: '1',
      eventdate: '06/02/2022 11:58:10 pm',
      location: '8655 North Bremetowe Pwy Chicago, IL 60606',
      battery: '100%',
      charger: 'Off',
      strap: 'On',
      speed: '0 mph',
    },
    {
      key: '1',
      eventdate: '06/02/2022 11:58:10 pm',
      location: '8655 North Bremetowe Pwy Chicago, IL 60606',
      battery: '100%',
      charger: 'Off',
      strap: 'On',
      speed: '0 mph',
    },
    {
      key: '1',
      eventdate: '06/02/2022 11:58:10 pm',
      location: '8655 North Bremetowe Pwy Chicago, IL 60606',
      battery: '100%',
      charger: 'Off',
      strap: 'On',
      speed: '0 mph',
    },
    {
      key: '1',
      eventdate: '06/02/2022 11:58:10 pm',
      location: '8655 North Bremetowe Pwy Chicago, IL 60606',
      battery: '100%',
      charger: 'Off',
      strap: 'On',
      speed: '0 mph',
    },
    {
      key: '1',
      eventdate: '06/02/2022 11:58:10 pm',
      location: '8655 North Bremetowe Pwy Chicago, IL 60606',
      battery: '100%',
      charger: 'Off',
      strap: 'On',
      speed: '0 mph',
    },
    {
      key: '1',
      eventdate: '06/02/2022 11:58:10 pm',
      location: '8655 North Bremetowe Pwy Chicago, IL 60606',
      battery: '100%',
      charger: 'Off',
      strap: 'On',
      speed: '0 mph',
    },
    {
      key: '1',
      eventdate: '06/02/2022 11:58:10 pm',
      location: '8655 North Bremetowe Pwy Chicago, IL 60606',
      battery: '100%',
      charger: 'Off',
      strap: 'On',
      speed: '0 mph',
    },
    {
      key: '1',
      eventdate: '06/02/2022 11:58:10 pm',
      location: '8655 North Bremetowe Pwy Chicago, IL 60606',
      battery: '100%',
      charger: 'Off',
      strap: 'On',
      speed: '0 mph',
    },
    {
      key: '1',
      eventdate: '06/02/2022 11:58:10 pm',
      location: '8655 North Bremetowe Pwy Chicago, IL 60606',
      battery: '100%',
      charger: 'Off',
      strap: 'On',
      speed: '0 mph',
    },
    {
      key: '1',
      eventdate: '06/02/2022 11:58:10 pm',
      location: '8655 North Bremetowe Pwy Chicago, IL 60606',
      battery: '100%',
      charger: 'Off',
      strap: 'On',
      speed: '0 mph',
    },
    
  ];

  const handleZoomChange = (e) => {
    
    if (mapRef&&mapRef.current&&mapRef.current.state&&mapRef.current.state.map&&mapRef.current.state.map.zoom!==1) {
      // console.log(mapRef.current.state.map.zoom, '=======handleZoomChange========', mapRef.current.state.map.center.lat(), mapRef.current.state.map.center.lng());
      // setZoomLevel(mapRef.current.state.map.zoom);
      localStorage.setItem('zpsZoomLevel', mapRef.current.state.map.zoom);
      localStorage.setItem('zpsCenterLat', mapRef.current.state.map.center.lat());
      localStorage.setItem('zpsCenterLng', mapRef.current.state.map.center.lng());
    }
  }
 
  const onLoadPoly = polyline => {
    
  };
  const shareLink = () =>{
    const url = window.location.origin+'/caseload/'+participantProfileData.id+'/'+(((typeof historyStartDate === 'string' || historyStartDate instanceof String )? historyStartDate:  historyStartDate.format("YYYY-MM-DDTHH:mm")+moment().format("Z").replaceAll(":","")) || moment().format("YYYY-MM-DDTHH:mm")+moment().format("Z").replaceAll(":","")) + "/" +((typeof historyEndDate === 'string' || historyEndDate instanceof String )? historyEndDate:  historyEndDate.format("YYYY-MM-DDTHH:mm")+moment().format("Z").replaceAll(":",""))+"/gps"
    setCopyUrl(url);
    setCopyModalVisible(true)
  
  }
  const copyUrlFun = () =>{
    navigator.clipboard.writeText(copyUrl);
    setCopyModalVisible(false)
    notification.success({
      description: "URL copied to clipboard",
      placement: "topRight",
      duration: 5,
    });
  }
  const onLoaded = (circle) => {
    // console.log('Circle onLoad circle: ', circle)
  };
  const onClickPoly = polyline =>{
    setIsPopUp(false);
   // console.log(polyline,"amdmmdfmfmf")
   // eventGpsData.map(el=>{
      //if(parseInt(el.latitude) == parseInt(polyline.latLng.lat()) && parseInt(el.longitude) ==parseInt(polyline.latLng.lng())){
        if(polyline?.vertex){
          const el = eventGpsData[polyline?.vertex];
          //setRowData(el);
          //console.log("elllll here",el);
          let geocoder = new window.google.maps.Geocoder;
          var latlng = new window.google.maps.LatLng(el?.latitude, el?.longitude);
          geocoder
          .geocode({
            location: latlng,
            
          })
          .then(({ results }) => {
            el.location = results[0].formatted_address;
            setRowData(el);
            setPosition({lat:el?.latitude,lng:el?.longitude});
           setIsPopUp(true);
          })
          .catch((e) =>
            {
              setRowData(el);
              setPosition({lat:el?.latitude,lng:el?.longitude});
           setIsPopUp(true);
            }
            
          );
           
        }
        
        // return false ;
    //   }
    // })
   
    
  }
  
  const options = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: true,
    visible: true,
    //radius: 30000,
   // geodesic: true,
    icons: [{
      icon: {path: window.google ? window.google.maps.SymbolPath.BACKWARD_CLOSED_ARROW: undefined},
        offset: '100%',
        repeat: '200px',
      offset: '100%',
  }],
    paths:path,
    zIndex: 1
  };
  return (
    <>
      <Row>
        <Col span={3}>
          <h2>GPS History</h2>
          </Col>
          <Col span={5}>
              <Button type="primary" onClick={(e) => {console.log(e)}} > Request Status Update </Button>
          </Col>
          <Col span={12}></Col>
          <Col span={4}>
          <Button
          onClick={shareLink}
          type="primary">Share
        </Button>
          <DownloadTableButton csvData={data} headers={csvHeaders} />
        </Col>
      </Row>

      {eventGpsData != undefined ?  (
        <Row>
          <Col span={10} className="cs_table">
            <Table onRow={(record, rowIndex) => {
    return {
      onClick: event => { 
        //console.log(record,"onclick")
         setIsPopUp(true);
         setPosition({lat:record?.latitude,lng:record?.longitude});
         
          //  fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${record?.latitude},${record?.longitude}&key=${googleKey}`).then(res => res.json())
          // .then(add => {
            
          //   if(add?.status =="OK"){
          //     record.location = add?.results[0].formatted_address;
          //   }
          // });
         // setRowData(record);
          let geocoder = new window.google.maps.Geocoder;
          var latlng = new window.google.maps.LatLng(record?.latitude, record?.longitude);
          geocoder
          .geocode({
            location: latlng,
            
          })
          .then(({ results }) => {
            record.location = results[0].formatted_address;
            setRowData(record);
          })
          .catch((e) =>
            {
              setRowData(record);
            }
            
          );
         
        //  if(record){
        //   setPosition({lat:record?.latitude,lng:record?.longitude});
        //  // var map = new window.google.maps.Map({});
        
        //  if(isPopUp == true){
        //   console.log(mapRef,"map here");
        //    var infowindow = new window.google.maps.InfoWindow({
        //      content: "Copenhagen"
        //    });
        //    infowindow.setPosition({lat:record?.latitude,lng:record?.longitude});
        //    mapRef.current.
        //    infowindow.open(mapRef);
        //  }
         
        //  }
      }}}} columns={gpsColumn} dataSource={eventGpsData} pagination={false}  />
          </Col>

          <Col span={14} style={{ height: "75vh",position: "sticky",top: "0"}}>
            <GoogleMap ref={mapRef} mapContainerStyle={containerStyle} onZoomChanged={handleZoomChange} center={center} zoom={zoomLevel} onBoundsChanged = {onBoundsChange} onLoad={onLoad} onUnmount={onUnmount} 
              >
              {/* <Marker position={mapData} /> */}
              <Polyline
              onLoad={onLoadPoly}
              onClick ={onClickPoly}
              path={path}
              options={options}
            />
            {isPopUp == true ? (
              <Marker
              position={position}
             
              >
            
              <InfoWindow
              // onCloseClick ={setIsPopUp(false)}
                onLoad={onLoadInfo}
                position={position}
              >
                <div style={divStyle}>
                <Row>
                  <Col><h4>Location Details</h4></Col>
                  </Row>
                  <Row>
                  <Col><b>Event Date: </b> {rowData?.eventdate}</Col>
                  </Row>
                  <Row>
                  <Col><b>Battery: </b> {rowData?.battery}</Col>
                  </Row>
                  <Row>
                  <Col><b>Charger: </b>{rowData?.charger}</Col>
                  </Row>
                  <Row>
                  <Col><b>Speed: </b>{rowData?.speed}</Col>
                  </Row>
                  <Row>
                  <Col><b>Strap: </b>{rowData?.strap}</Col>
                  </Row>
                  <Row>
                  <Col><b>Location: </b>{rowData?.latitude},{rowData?.longitude}</Col>
                  </Row>
                  <Row>
                  <Col><b>Address: </b>{rowData?.location}</Col>
                  </Row>
                </div>
              </InfoWindow>
             </Marker>
            ): (<></>)
          }
          {zoneData.map((el,i)=>{
            if(el.typeOfShape === 'CIRCLE'){
              console.log("work here in shape",el?.isExclusionZone);
              return <>
              <Marker key = {i} position={{ lat: el?.coordinates[0]?.latitude, lng: el?.coordinates[0]?.longitude }} />
              <Circle
                    onLoad={onLoaded}
                    // ref={(ref) => (circleRef = ref)}
                    // onRadiusChanged={dragCricle}
                    // onUnmount={onUnmountLoad}
                    radius={el?.radius}
                    center={{ lat: el?.coordinates[0]?.latitude, lng: el?.coordinates[0]?.longitude }}
                    options={{
                      strokeColor: el.isExclusionZone ?"#ff0000" : "#32a858",
                      strokeOpacity: 0.8,
                      strokeWeight: 2,
                      fillColor: el.isExclusionZone ?"#ff0000" : "#32a858",
                      fillOpacity: 0.35,
                      clickable: false,
                      draggable: false,
                      editable: false,
                      radius: 50,
                      visible: true,
                      zIndex: 1,
                    }}
                  />
              </>
            }
            if(el.typeOfShape === 'POLYGON'){
              let declaredPath = [];
              if(el.coordinates.length>0){
                el.coordinates.forEach(element => {
                  declaredPath.push(
                    {
                      lat: parseFloat(element.latitude),
                      lang: parseFloat(element.longitude),
                    }
                  );
                });
                console.log("declaredPath",declaredPath);
              }
              return <>
               <Marker key = {i} position={{ lat: el?.coordinates[0]?.latitude, lng: el?.coordinates[0]?.longitude }} />

               <Polygon
                draggable={false}
                editable={false}
                options={{
                  strokeColor: el.isExclusionZone ?"#32a858" : "#ff0000",
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: el.isExclusionZone ?"#32a858" : "#ff0000",
                  fillOpacity: 0.35,
                  visible: true,
                  zIndex: 1,
                }}
                onClick={function (event) {
                  console.log("event here:", event);
                }}
                paths={declaredPath}
              />
              </>
            }
          })

          }
            </GoogleMap>
          </Col>
        </Row>
      ): (
        <></>
      )}
            <StyledModal visible={copyModalVisible} width={800} footer={false} closable={false} onCancel={handleCancel}
        title={[
          <Row>
            <Col md={9} style={{marginTop:'5px',fontWeight: 'bold'}}>  <img alt="Shop Life Logo" src={Logo} style={{width: '120px'}} /> &nbsp; &nbsp; &nbsp;Copy Share Url</Col>
            <Col md={15}>
             
              <Button style={{float: 'right'}} onClick={handleCancel}>Cancel</Button>
            </Col>
          </Row>
        ]}        
      >
        
        <Row>
          <Col>
          <InputAntd
              inputWidth="650px"
              label="Copy Share Url"
              value={copyUrl}
            />
            <Button
          onClick={copyUrlFun}
          type="primary">Copy Link
        </Button>
          </Col>
        </Row>
       
      </StyledModal>
    </>
  );
};

export default GpsHistory;
